import { Theme } from "@mui/material/styles";
import { blackTone900, grayScale } from "../constants";

export const TypographyStyles = (theme: Theme) => ({
  //64 px. / Semi Bold
  h1: {
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "36px",
    letterSpacing: "-0.32px",

    [theme.breakpoints.up("lg")]: {
      fontSize: "64px",
      lineHeight: "68px",
      fontWeight: 600,
      letterSpacing: "-0.64px",
    },
    // [theme.breakpoints.up("laptop")]: {
    //   fontSize: "30px",
    //   lineHeight: "37.8px",
    // },
  },
  //48 px. / Semibold
  h2: {
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "32px",
    [theme.breakpoints.up("lg")]: {
      fontSize: "48px",
      lineHeight: "40px",
      letterSpacing: "-0.48px",
    },
    // [theme.breakpoints.up("laptop")]: {
    //   fontSize: "30px",
    //   lineHeight: "37.8px",
    // },
  },
  //40 px. / Semibold
  h3: {
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "32px",
    color: blackTone900,
    [theme.breakpoints.up("md")]: {
      fontSize: "40px",
      lineHeight: "52px",
      letterSpacing: "-0.4px",
    },
    // [theme.breakpoints.up("laptop")]: {
    //   fontSize: "24px",
    //   lineHeight: "30.24px",
    // },
  },
  //28 px. / SemiBold
  h4: {
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "24px",
    [theme.breakpoints.up("lg")]: {
      fontSize: "28px",
      lineHeight: "36px",
      letterSpacing: "-0.28px",
    },
  },
  //24 px. / Semibold
  h5: {
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "32px",
    // letterSpacing: "-0.28px",
    // [theme.breakpoints.up("tablet")]: {
    //   fontSize: "16px",
    //   lineHeight: "20.16px",
    // },
    // [theme.breakpoints.up("laptop")]: {
    //   fontSize: "18px",
    //   lineHeight: "22.68px",
    // },
  },
  //18 px. / Semibold
  h6: {
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "normal",
  },
  //16 px. / Semibold
  subtitle1: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "normal",
    color: grayScale,
  },
  //14 px. / Semibold
  subtitle2: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "normal",
    color: grayScale,
  },
  //18 px. / Medium
  button: {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "28px",
    color: grayScale,
  },
  //18 px. / Regular
  body1: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "150%",
    [theme.breakpoints.up("lg")]: {
      fontSize: "18px",
      lineHeight: "28px",
    },
  },
  //16 px. / Regular
  body2: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  //12 PX. / SEMIBOLD
  caption1: {
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "0.6px",
    color: "rgba(0, 0, 0, 0.80)",
  },
  //16 PX. / SEMIBOLD
  caption2: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "0.6px",
    color: "rgba(0, 0, 0, 0.80)",
    [theme.breakpoints.up("lg")]: {
      fontSize: "16px",
    },
  },
});
