import React from "react";

import TitleSection from "./components/TitleSection";
import HowToSection from "./components/HowToSection";
import RevenueShareSection from "./components/RevenueShareSection";
import Footer from "./components/Footer";
import UpcomingInnovationsSection from "./components/UpcomingInnovations";
import Header from "./components/Header";
import { Box } from "@mui/material";
import RoadMapSection from "./components/RoadMapSection";
import ParlaysSection from "./components/ParlaysSection";

function App() {
  return (
    <div>
      <Header />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          paddingX: {
            xs: "24px",
            md: "40px",
          },
        }}
      >
        <TitleSection />
        <HowToSection />
        <UpcomingInnovationsSection />
        <ParlaysSection />
        <RoadMapSection />
        <RevenueShareSection />
      </Box>
      <Footer />
    </div>
  );
}

export default App;
