import { Drawer, IconButton, Stack, Link, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { blackTone900 } from '../styles/constants'
import LogoSVG from '../assets/new-design/LogoSVG'

type MenuDrawerProps = {
  open: boolean
  onClose: () => void
}

export const MenuDrawer = ({ open, onClose }: MenuDrawerProps) => {
  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '100%',
          backgroundColor: blackTone900,
          justifyContent: 'center',
          px: { xs: '36px', sm: '130px' },
          position: 'relative',
        },
      }}
    >
      <IconButton
        sx={{ position: 'absolute', top: '32px', right: '28px' }}
        onClick={onClose}
      >
        <CloseIcon color='secondary' />
      </IconButton>
      <Stack gap={7} alignItems={'center'} width={'100%'}>
        <LogoSVG width='200' height='100%' />
        <Stack gap={3} width={'100%'} maxWidth={'300px'}>
          <Link
            underline='none'
            href='https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xc01b733b2ef479086b80949676a673346b531aa2&chain=mainnet'
            target='_blank'
            rel='noopener'
          >
            <Button variant='contained' color='primary' fullWidth size='large'>
              Buy $League
            </Button>
          </Link>
          <Link
            underline='none'
            href='https://t.me/leaguebot_official'
            target='_blank'
            rel='noopener'
          >
            <Button variant='contained' color='primary' fullWidth size='large'>
              Telegram
            </Button>
          </Link>
        </Stack>
      </Stack>
    </Drawer>
  )
}

export default MenuDrawer
