export const ThickSvg = ({ width = "32", height = "32" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M21.737 9.3104C21.455 9.15319 21.1448 9.05321 20.8242 9.01618C20.5036 8.97916 20.1788 9.00581 19.8685 9.09462C19.5582 9.18343 19.2684 9.33265 19.0157 9.53375C18.7629 9.73485 18.5523 9.98389 18.3957 10.2666L13.8345 18.4898L11.2241 15.8747C10.9974 15.6396 10.7263 15.4521 10.4266 15.3231C10.1268 15.1941 9.80445 15.1262 9.47822 15.1234C9.152 15.1205 8.82848 15.1828 8.52654 15.3066C8.2246 15.4303 7.95028 15.6131 7.7196 15.8442C7.48892 16.0753 7.30649 16.3501 7.18296 16.6526C7.05942 16.9551 6.99726 17.2792 7.00009 17.606C7.00293 17.9328 7.0707 18.2558 7.19947 18.5561C7.32823 18.8564 7.5154 19.1279 7.75005 19.355L12.6638 24.2776C13.1282 24.744 13.7547 25 14.4008 25L14.7411 24.9754C15.1177 24.9226 15.4769 24.783 15.7904 24.5675C16.104 24.352 16.3634 24.0666 16.5481 23.7337L22.6903 12.6578C22.8471 12.3753 22.9469 12.0646 22.9838 11.7435C23.0208 11.4224 22.9942 11.0971 22.9057 10.7863C22.8172 10.4755 22.6684 10.1851 22.4679 9.9319C22.2674 9.67866 22.019 9.46748 21.737 9.3104Z"
      fill="#2D2D2D"
    />
  </svg>
);

export default ThickSvg;
