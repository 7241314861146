import {
  Link,
  Stack,
  Typography,
  Box,
  IconButton,
  Button,
  Divider,
} from "@mui/material";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import {
  blackTone100,
  blackTone500,
  blackTone800,
  white,
} from "../styles/constants";
import LionImg from "../assets/new-design/LionImg.png";
import { useState } from "react";
import VideoPlayer from "./VideoPlayer";

export const HowToSection = () => {
  const [openPlayer, setOpenPlayer] = useState(false);

  return (
    <Stack
      id="how-to"
      gap={"44px"}
      alignItems={"center"}
      width={"100%"}
      maxWidth={"1520px"}
      py={"100px"}
    >
      <Stack gap={2}>
        <Typography variant="h3" color={white} textAlign={"center"}>
          Elevate Your Betting with Our Swift Telegram Bot
        </Typography>
        <Typography
          variant="body1"
          textAlign={"center"}
          color={blackTone100}
          fontSize={{ xs: "14px", lg: "18px" }}
        >
          Enjoy seamless and ultra-fast betting on the go with our user-friendly
          Telegram bot, available for free.
        </Typography>
      </Stack>
      <Stack gap={"36px"} width="100%" alignItems={"center"}>
        <Box
          sx={{
            width: { xs: "100%", md: "80%" },
            backgroundColor: blackTone800,
            padding: "16px",
            borderRadius: "40px",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#000",
              borderRadius: "36px",
              height: "388px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Box sx={{ position: "absolute", top: 16 }}>
              <img src={LionImg} alt="league-bot" width={"80px"} />
            </Box>
            <IconButton
              sx={{
                border: `2px solid ${blackTone500}`,
                width: "106px",
                height: "106px",
                transition: "all .3s ease-in-out",
                "&:hover": {
                  width: "90px",
                  height: "90px",
                  transform: "scale(1.1)",
                },
              }}
              onClick={() => setOpenPlayer(true)}
            >
              <PlayArrowRoundedIcon
                fontSize="large"
                sx={{ color: blackTone100 }}
              />
            </IconButton>
            <VideoPlayer
              open={openPlayer}
              onClose={() => setOpenPlayer(false)}
            />
          </Box>
        </Box>
        <Stack
          flexDirection={{ xs: "column", md: "row" }}
          gap={"20px"}
          display={{ xs: "none", md: "flex" }}
        >
          <Link
            href="https://t.me/leaguebot_ultimate_bot"
            underline="none"
            target="_blank"
            rel="noopener"
          >
            <Button variant="text" color="primary">
              Start Betting
            </Button>
          </Link>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ width: "1px", borderColor: blackTone500 }}
          />
          <Link
            underline="none"
            href="https://t.me/leaguebot_official"
            target="_blank"
            rel="noopener"
          >
            <Button variant="text" color="primary">
              Telegram
            </Button>
          </Link>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ width: "1px", borderColor: blackTone500 }}
          />
          <Link
            underline="none"
            href="https://twitter.com/teamleaguebot"
            target="_blank"
            rel="noopener"
          >
            <Button variant="text" color="primary">
              Twitter
            </Button>
          </Link>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ width: "1px", borderColor: blackTone500 }}
          />
          <Link
            underline="none"
            href="https://www.dextools.io/app/en/ether/pair-explorer/0x4251b18464cae6f0844a51cf285199f45b77fa81"
            target="_blank"
            rel="noopener"
          >
            <Button variant="text" color="primary">
              DEXTools
            </Button>
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default HowToSection;
