import { ButtonProps } from '@mui/material'
import { createTheme, Theme } from '@mui/material/styles'
import {
  blackTone500,
  blackTone700,
  blackTone900,
  mainOrange,
  orangeBg,
  orangeBgLight,
  orangeGlow,
  white,
} from './constants'
import { TypographyStyles } from './mui-components/typography'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    caption1: React.CSSProperties
    caption2: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    caption1: React.CSSProperties
    caption2: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    caption1: true
    caption2: true

    caption: false
  }
}

const BREAKPOINTS = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 2000,
    },
  },
}

const fontFamily = '"Pretendard", sans-serif'
export let theme = createTheme(BREAKPOINTS)
theme = createTheme(theme, {
  palette: {
    primary: {
      main: mainOrange,
      dark: '#B55206',
    },
    secondary: {
      main: '#FFF',
    },
    text: {
      primary: '#FFF',
      secondary: mainOrange,
    },
    background: {
      paper: blackTone900,
      default: blackTone900,
    },
  },
  typography: {
    fontFamily,
    allVariants: {
      fontFamily,
    },
    ...TypographyStyles(theme),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({
          ownerState,
          theme,
        }: {
          theme: Theme
          ownerState: ButtonProps
        }) => ({
          fontFamily,
        }),
        containedPrimary: ({
          ownerState,
          theme,
        }: {
          theme: Theme
          ownerState: ButtonProps
        }) => ({
          borderRadius: '12px',
          padding: '17.5px 32px',
          border: `1px solid ${orangeGlow}`,
          backgroundColor: orangeBg,
          height: '54px',
          color: white,
          fontSize: '14px',
          fontWeight: 600,
          lineHeight: 'normal',
          letterSpacing: '0.32px',
          boxShadow: '0px 0px 21px 1px rgba(255,132,0,1)',
          '&:hover': {
            backgroundColor: blackTone500,
            border: `1px solid ${orangeGlow}`,
            boxShadow: '0px 0px 21px 1px rgba(255,132,0,1)',
          },
          '&:focus': {
            backgroundColor: orangeGlow,
            color: blackTone900,
            border: `1px solid ${orangeGlow}`,
            boxShadow: '0px 0px 21px 1px rgba(255,132,0,1)',
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '16px',
          },
        }),

        outlinedPrimary: ({
          ownerState,
          theme,
        }: {
          theme: Theme
          ownerState: ButtonProps
        }) => ({
          borderRadius: '12px',
          padding: '12px 24px',
          border: `1px solid ${orangeGlow}`,
          backgroundColor: blackTone900,

          color: white,
          fontSize: '14px',
          fontWeight: 600,
          lineHeight: 'normal',
          '&:hover': {
            backgroundColor: blackTone500,
            border: `1px solid ${orangeGlow}`,
          },
          '&:focus': {
            backgroundColor: orangeGlow,
            color: blackTone900,
            border: `1px solid ${orangeGlow}`,
          },
        }),
        outlinedSecondary: ({
          ownerState,
          theme,
        }: {
          theme: Theme
          ownerState: ButtonProps
        }) => ({
          borderRadius: '12px',
          padding: '12px 24px',
          border: `1px solid ${blackTone500}`,
          backgroundColor: blackTone700,
          lineHeight: 'normal',
          color: white,
          fontSize: '14px',
          fontWeight: 600,
          height: '41px',
          '&:hover': {
            backgroundColor: blackTone500,
            border: `1px solid ${blackTone500}`,
          },
          '&:focus': {
            backgroundColor: orangeGlow,
            color: blackTone900,
            border: `1px solid ${orangeGlow}`,
          },
          ...(ownerState.size === 'large' && {
            height: '54px',
            padding: '16px 32px',
          }),

          [theme.breakpoints.up('md')]: {
            fontSize: '16px',
          },
        }),
        textSecondary: ({
          ownerState,
          theme,
        }: {
          theme: Theme
          ownerState: ButtonProps
        }) => ({}),
        textPrimary: ({
          ownerState,
          theme,
        }: {
          theme: Theme
          ownerState: ButtonProps
        }) => ({
          padding: '8px 20px',
          borderRadius: '12px',
          border: `1px solid ${mainOrange}`,
          backgroundColor: orangeBgLight,
          color: mainOrange,
          fontSize: '14px',
          fontWeight: 600,
          lineHeight: 'normal',
          textTransform: 'none',
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: { borderColor: '#D9D9D9', width: '100%' },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily,
        },
      },
    },
  },
})
