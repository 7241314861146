import { Card, Grid, Stack, Typography, Box, Divider } from '@mui/material'
import {
  blackTone100,
  blackTone600,
  blackTone800,
  mainOrange,
} from '../styles/constants'

import BrainBoldSvg from '../assets/new-design/BrainBoldSvg'

import ThickSvg from '../assets/new-design/ThickSvg'

type RoadMapItem = {
  period: string
  title: string
  done?: boolean
}

const roadMapItems: RoadMapItem[] = [
  { period: 'Q4 - 2023', title: 'Develop Copy-trading Mechanisms' },
  {
    period: 'Q4 - 2023',
    title: 'Parlay Dashboard',
  },
  {
    period: 'Q4 - 2023',
    title: ' Betting for a Living. International Expansion',
  },
  { period: 'Q4 - 2023', title: 'IParlay: The AI Betting Assistant' },
  {
    period: 'Q4 - 2023',
    title: 'LeagueBot NFT Series',
  },
  { period: 'Q4 - 2023', title: 'Referrals System' },
  { period: 'Q4 - 2023', title: 'Revenue Share' },
  {
    period: 'Q1 - 2024',
    title: 'More Betting Options',
  },
  { period: 'Q1 - 2024', title: 'Profit Dashboard ' },

  { period: 'Q1 - 2024', title: 'Engage Your Community' },
  { period: 'Q2 - 2024', title: 'Live Betting' },
]

export const RoadMapSection = () => {
  return (
    <Card
      id='roadmap'
      sx={{
        backgroundColor: blackTone800,
        borderRadius: '40px',
        padding: {
          xs: '88px 24px',
          md: '94px 40px 101px 40px',
          lg: '94px 140px 101px 140px',
          xl: '94px 258px 101px 258px',
        },
        marginTop: '192px',
        marginBottom: '100px',
        width: '100%',
        maxWidth: '1520px',
      }}
    >
      <Stack gap={'112px'}>
        <Stack gap={1}>
          <Typography variant='h2'>Our Roadmap</Typography>
          <Typography
            variant='body1'
            color={blackTone100}
            fontSize={{ xs: '14px', lg: '18px' }}
          >
            Unveiling our roadmap: charting the future, highlighting milestones,
            and guiding our journey. Stay tuned for what's next!
          </Typography>
        </Stack>
        <Stack gap={{ xs: 8, md: 8 }}>
          {roadMapItems.map((item) => (
            <Grid
              key={item.title}
              container
              justifyContent={'space-between'}
              spacing={{ xs: 3, lg: 0 }}
            >
              <Grid item xs={6} md={2} container alignItems={'center'}>
                <Typography
                  key={item.title}
                  variant='caption2'
                  color={item.done ? mainOrange : blackTone100}
                >
                  [&nbsp;&nbsp;&nbsp;&nbsp;{item.period}
                  &nbsp;&nbsp;&nbsp;&nbsp;]
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                md={2}
                container
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Divider
                  sx={{
                    borderColor: item.done ? mainOrange : blackTone600,
                    width: { xs: '100%', md: item.done ? '100%' : '80%' },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                width='100%'
                container
                alignItems={'center'}
              >
                <Stack
                  key={item.title}
                  flexDirection={'row'}
                  gap={2}
                  alignItems={'center'}
                >
                  {item.done ? (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: { xs: '32px', md: '54px' },
                        height: { xs: '32px', md: '54px' },
                        alignItems: 'center',
                        padding: { xs: 0, md: '11px' },
                        borderRadius: { xs: '10px', md: '16px' },
                        background: mainOrange,
                      }}
                    >
                      <ThickSvg width='100%' />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: { xs: '32px', md: '54px' },
                        height: { xs: '32px', md: '54px' },
                        alignItems: 'center',
                        padding: { xs: '6px', md: '11px' },
                        borderRadius: { xs: '10px', md: '16px' },

                        background:
                          'linear-gradient(45deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)',
                        backdropFilter: 'blur(20px)',
                        border: `1px solid ${blackTone600}`,
                      }}
                    >
                      <BrainBoldSvg width='100%' />
                    </Box>
                  )}
                  <Typography variant='h6'>{item.title}</Typography>
                </Stack>
              </Grid>
            </Grid>
          ))}
        </Stack>
      </Stack>
    </Card>
  )
}
export default RoadMapSection
