import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./fonts/Pretendard/Pretendard-Black.otf";
import "./fonts/Pretendard/Pretendard-Bold.otf";
import "./fonts/Pretendard/Pretendard-ExtraBold.otf";
import "./fonts/Pretendard/Pretendard-ExtraLight.otf";
import "./fonts/Pretendard/Pretendard-Light.otf";
import "./fonts/Pretendard/Pretendard-Medium.otf";
import "./fonts/Pretendard/Pretendard-Regular.otf";
import "./fonts/Pretendard/Pretendard-SemiBold.otf";
import "./fonts/Pretendard/Pretendard-Thin.otf";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { theme } from "./styles/theme";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
