import { Link, Grid, Stack, Typography, Button, Card, Box } from '@mui/material'

import { blackTone100, blackTone800 } from '../styles/constants'
import LogoSVG from '../assets/new-design/LogoSVG'

export const Footer = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        paddingX: {
          xs: '0',
          md: '40px',
        },
        mt: 10,
      }}
    >
      <Card
        sx={{
          backgroundColor: blackTone800,
          padding: { xs: '30px 16px 50px', md: '61px 126px 44px' },
          borderRadius: { xs: '12px 12px 0 0', md: '40px 40px 0 0' },
          maxWidth: '1520px',
          width: '100%',
        }}
      >
        <Grid container gap={{ xs: 8, md: 0 }}>
          <Grid item xs={12} md={6}>
            <Stack height='100%' justifyContent={'space-between'} gap={4}>
              <LogoSVG width={'238'} height={'68'} />
              {/* <Typography variant='h4'>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration.
              </Typography> */}
              <Stack sx={{ flexDirection: 'row' }} gap={2}>
                <Link
                  underline='none'
                  href='https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xc01b733b2ef479086b80949676a673346b531aa2&chain=mainnet'
                  target='_blank'
                  rel='noopener'
                >
                  <Button variant='outlined' color='secondary'>
                    Buy $League
                  </Button>
                </Link>
                <Link href='https://t.me/leaguebot_official' target='_blank'>
                  <Button variant='outlined'>Telegram</Button>
                </Link>
              </Stack>

              {/* <Typography fontSize={"16px"} fontWeight={700}>
                © 2023 League Bot. All rights reserved.
              </Typography> */}
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            container
            justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
          >
            <Grid
              item
              xs={6}
              md={5}
              container
              justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
            >
              <Stack gap={'12px'}>
                <Typography variant='h6' paddingBottom='32px'>
                  Navigate
                </Typography>
                <Typography
                  component='a'
                  href='/'
                  sx={{
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'underline' },
                  }}
                  variant='subtitle2'
                  color={blackTone100}
                >
                  Home
                </Typography>
                <Typography
                  component='a'
                  href='#how-to'
                  sx={{
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'underline' },
                  }}
                  variant='subtitle2'
                  color={blackTone100}
                >
                  How to use
                </Typography>
                <Typography
                  component='a'
                  href='#upcoming-innovations'
                  sx={{
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'underline' },
                  }}
                  variant='subtitle2'
                  color={blackTone100}
                >
                  Upcoming innovations
                </Typography>
                <Typography
                  component='a'
                  href='#roadmap'
                  sx={{
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'underline' },
                  }}
                  variant='subtitle2'
                  color={blackTone100}
                >
                  Roadmap
                </Typography>
                <Typography
                  component='a'
                  href='#revenue-share'
                  sx={{
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'underline' },
                  }}
                  variant='subtitle2'
                  color={blackTone100}
                >
                  Revenue share
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
              container
              justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
            >
              <Stack gap={'12px'}>
                <Typography variant='h6' paddingBottom='32px'>
                  Join us
                </Typography>
                <Typography
                  variant='subtitle2'
                  color={blackTone100}
                  component='a'
                  href='https://t.me/leaguebot_official'
                  sx={{
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'underline' },
                  }}
                >
                  Telegram
                </Typography>
                <Typography
                  variant='subtitle2'
                  color={blackTone100}
                  component='a'
                  href='https://twitter.com/teamleaguebot'
                  sx={{
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'underline' },
                  }}
                >
                  Twitter
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Box>
  )
}

export default Footer
