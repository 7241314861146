import { Grid, Stack, Button, Typography, Link } from '@mui/material'

import PhonesAnimations from './PhonesAnimations'

export const TitleSection = () => {
  return (
    <Grid
      container
      justifyContent={'space-between'}
      alignItems={'center'}
      height={{ xs: 'auto', md: 'calc(100vh - 74px)' }}
      maxWidth={'1520px'}
      flexDirection={{ xs: 'column', md: 'row-reverse' }}
    >
      <Grid
        item
        xs={12}
        md={6}
        height={'100%'}
        width='100%'
        container
        justifyContent={'center'}
      >
        <PhonesAnimations />
      </Grid>
      <Grid item xs={12} md={6} py={{ xs: '20px', md: 0 }}>
        <Stack gap={3} maxWidth={'642px'} paddingRight='24px'>
          <Typography variant='h1'>
            Start Your Wonderful Journey in Sports Betting
          </Typography>
          <Typography variant={'body1'} textAlign={'justify'}>
            LeagueBot's goal is to offer a set of crypto-focused tools for
            Telegram users. Our innovative betting bot aims to create an
            ecosystem that meets the needs of crypto enthusiasts right on
            Telegram, the prominent messaging platform for the crypto community.
          </Typography>
          <Stack flexDirection={'row'} gap={1} paddingTop={'42px'}>
            <Link
              underline='none'
              href='https://t.me/leaguebot_ultimate_bot'
              target='_blank'
              rel='noopener'
            >
              <Button variant='contained' color='primary'>
                Launch Bot
              </Button>
            </Link>
            <Link
              underline='none'
              href='https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xc01b733b2ef479086b80949676a673346b531aa2&chain=mainnet'
              target='_blank'
              rel='noopener'
            >
              <Button variant='outlined' color='secondary' size='large'>
                Buy $League
              </Button>
            </Link>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  )
}
export default TitleSection
