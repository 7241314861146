export const mainOrange = "#FF8400";
export const secondaryOrange = "#FFA442";
export const orangeGlow = "#FFEBD5";

export const blackTone900 = "#0B0B0B";
export const blackTone800 = "#121212";
export const blackTone700 = "#222020";
export const blackTone600 = "#2D2D2D";
export const blackTone500 = "#4C4949";
export const blackTone100 = "#B5B5B5";
export const white = "#FFF";

export const grayScale = "#202022";

export const orangeBg = "#17110A";
export const orangeBgLight = "#171615";
