import { Card, Box, Stack, Typography } from '@mui/material'
import {
  blackTone100,
  blackTone600,
  blackTone800,
  mainOrange,
} from '../styles/constants'

import ThickSvg from '../assets/new-design/ThickSvg'
import BrainBoldSvg from '../assets/new-design/BrainBoldSvg'

export const UpcomingInnovationsSection = () => {
  const upcomingInnovations = [
    {
      title: 'AI Assisting Betting',
      subtitle:
        'An AI tool to assist you in determining the best place to make your bet.',
      isDone: false,
    },
    {
      title: 'Betting for a Living',
      subtitle: 'Engage your community and compete with them.',
      isDone: false,
    },
    {
      title: 'Behavioral AI',
      subtitle:
        'Analyze your bets and your emotions. Become the ultimate betting machine.',
      isDone: false,
    },
    {
      title: 'Vaults',
      subtitle:
        'Participate in our weekly lottery and get a chance to win from 5% of our income.',
      isDone: false,
    },
    {
      title: 'World Bettification',
      subtitle: 'Bet on world events, politics, and everything else.',
      isDone: false,
    },
  ]
  return (
    <>
      <Stack
        display={{ xs: 'flex', md: 'none' }}
        flexDirection={'column'}
        alignItems={'center'}
        width='100%'
        justifyContent={'center'}
        gap={3}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '316px',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '30px',
            gap: '10px',
            borderRadius: '32px',
            background:
              'linear-gradient(45deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)',
            backdropFilter: 'blur(20px)',
            border: `1px solid ${blackTone600}`,
          }}
        >
          <Typography variant='h2'>24/7</Typography>
          <Typography variant='h6'>Support</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '316px',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '30px',
            gap: '10px',
            borderRadius: '32px',
            background:
              'linear-gradient(45deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)',
            backdropFilter: 'blur(20px)',
            border: `1px solid ${blackTone600}`,
          }}
        >
          <Typography variant='h2'>+$15,193</Typography>
          <Typography variant='h6'>Weekly Trading volume</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '316px',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '30px',
            gap: '10px',
            borderRadius: '32px',
            background:
              'linear-gradient(45deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)',
            backdropFilter: 'blur(20px)',
            border: `1px solid ${blackTone600}`,
          }}
        >
          <Typography variant='h2' textAlign={'center'}>
            +455
          </Typography>
          <Typography variant='h6' textAlign={'center'}>
            New users this month
          </Typography>
        </Box>
      </Stack>
      <Card
        id='upcoming-innovations'
        sx={{
          backgroundColor: blackTone800,
          borderRadius: '40px',
          padding: {
            xs: '88px 24px',
            md: '248px 40px 88px 40px',
            lg: '248px 140px 88px 140px',
            xl: '248px 258px 88px 258px',
          },
          marginY: { xs: '80px', md: '192px' },
          width: '100%',
          position: 'relative',
          overflow: 'initial',
          maxWidth: '1520px',
        }}
      >
        <Stack
          display={{ xs: 'none', md: 'flex' }}
          flexDirection={'row'}
          alignItems={'center'}
          width='100%'
          justifyContent={'center'}
          gap={3}
          px={{ md: '40px', lg: '140px', xl: '258px' }}
          sx={{
            position: 'absolute',
            top: '-127px',
            left: '0',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: { xs: '120px', md: '295px' },
              height: { xs: '100px', md: '189px' },
              alignItems: 'center',
              flexDirection: 'column',
              padding: '30px',
              gap: '10px',
              borderRadius: '32px',
              background:
                'linear-gradient(45deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)',
              backdropFilter: 'blur(20px)',
              border: `1px solid ${blackTone600}`,
            }}
          >
            <Typography variant='h2'>24/7</Typography>
            <Typography variant='h6'>Support</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              height: { xs: '200px', md: '253px' },
              width: { xs: '150px', md: '368px' },
              alignItems: 'center',
              flexDirection: 'column',
              padding: '30px',
              gap: '10px',
              borderRadius: '32px',
              background:
                'linear-gradient(45deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)',
              backdropFilter: 'blur(20px)',
              border: `1px solid ${blackTone600}`,
            }}
          >
            <Typography variant='h2'>+$15,193</Typography>
            <Typography variant='h6'>Weekly Trading volume</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: { xs: '120px', md: '295px' },
              height: { xs: '100px', md: '189px' },
              alignItems: 'center',
              flexDirection: 'column',
              padding: '30px',
              gap: '10px',
              borderRadius: '32px',
              background:
                'linear-gradient(45deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)',
              backdropFilter: 'blur(20px)',
              border: `1px solid ${blackTone600}`,
            }}
          >
            <Typography variant='h2' textAlign={'center'}>
              +455
            </Typography>
            <Typography variant='h6' textAlign={'center'}>
              New users this month
            </Typography>
          </Box>
        </Stack>
        <Stack gap={'112px'}>
          <Stack gap={2}>
            <Typography variant='h2'>Upcoming Innovations</Typography>
            <Typography
              variant='body1'
              color={blackTone100}
              fontSize={{ xs: '14px', lg: '18px' }}
            >
              Our goal is to expand the project and propel $LEAGUE to its
              maximum potential. To achieve this, we've created a preliminary
              roadmap with additional improvements.
            </Typography>
          </Stack>
          <Stack gap={'56px'}>
            {upcomingInnovations.map((innovation, index) => (
              <Stack
                key={innovation.title}
                flexDirection={'row'}
                gap={{ xs: '20px', md: 4 }}
              >
                {innovation.isDone ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: { xs: '32px', md: '54px' },
                      height: { xs: '32px', md: '54px' },
                      alignItems: 'center',
                      padding: { xs: 0, md: '11px' },
                      borderRadius: { xs: '10px', md: '16px' },
                      background: mainOrange,
                    }}
                  >
                    <ThickSvg width='100%' />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: { xs: '32px', md: '54px' },
                      height: { xs: '32px', md: '54px' },
                      alignItems: 'center',
                      padding: { xs: '6px', md: '11px' },
                      borderRadius: { xs: '10px', md: '16px' },
                      background:
                        'linear-gradient(45deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)',
                      backdropFilter: 'blur(20px)',
                      border: `1px solid ${blackTone600}`,
                    }}
                  >
                    <BrainBoldSvg width='100%' />
                  </Box>
                )}
                <Stack gap={0.5}>
                  <Typography variant='h6' color={mainOrange}>
                    {innovation.title}
                  </Typography>
                  <Typography variant='body2' color={blackTone100}>
                    {innovation.subtitle}
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Card>
    </>
  )
}
export default UpcomingInnovationsSection
