// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;700;800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: #0b0b0b;
  margin: 0;
  font-family: "Pretendard", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* @font-face {
  font-family: "PretendardSemiBold";
  src: local("PretendardSemiBold"),
    url("./fonts/Pretendard/Pretendard-SemiBold.otf") format("truetype");
  font-weight: 600;
} */

@keyframes up {
  50% {
    transform: translateY(-35px);
  }
}

@keyframes down {
  50% {
    transform: translateY(35px);
  }
}

/* @keyframes glowDown {
  50% {
    transform: translateY(35px);
  }
} */
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,yBAAyB;EACzB,SAAS;EACT,qCAAqC;EACrC,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;;;;;GAKG;;AAEH;EACE;IACE,4BAA4B;EAC9B;AACF;;AAEA;EACE;IACE,2BAA2B;EAC7B;AACF;;AAEA;;;;GAIG","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Inter:wght@400;700;800&display=swap\");\n\nbody {\n  background-color: #0b0b0b;\n  margin: 0;\n  font-family: \"Pretendard\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n/* @font-face {\n  font-family: \"PretendardSemiBold\";\n  src: local(\"PretendardSemiBold\"),\n    url(\"./fonts/Pretendard/Pretendard-SemiBold.otf\") format(\"truetype\");\n  font-weight: 600;\n} */\n\n@keyframes up {\n  50% {\n    transform: translateY(-35px);\n  }\n}\n\n@keyframes down {\n  50% {\n    transform: translateY(35px);\n  }\n}\n\n/* @keyframes glowDown {\n  50% {\n    transform: translateY(35px);\n  }\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
