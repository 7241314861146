import { Stack, Typography } from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { blackTone100 } from "../styles/constants";

export const ParlaysSection = () => {
  return (
    <Stack alignItems={"center"} gap={6} my={4}>
      <EmojiEventsIcon
        color="primary"
        sx={{ width: "106px", height: "106px" }}
      />
      <Typography
        variant="h2"
        color="primary"
        textAlign={"center"}
        lineHeight={"150%"}
      >
        Parlays <span style={{ color: "#FFF" }}>are now available only on</span>{" "}
        LEAGUE BOT
      </Typography>
      <Stack gap={2} maxWidth={"642px"}>
        <Typography variant="h1" textAlign={"center"}>
          PARLAYS POWER
        </Typography>
        <Typography variant="body1" color={blackTone100} textAlign={"center"}>
          Elevate your betting game with parlays! Combine multiple bets for a
          shot at bigger wins. Remember, all bets must win for the grand prize!
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ParlaysSection;
