import { Link, Stack, Button, Box, IconButton } from "@mui/material";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import LogoSVG from "../assets/new-design/LogoSVG";
import { blackTone900 } from "../styles/constants";
import MenuDrawer from "./MenuDrawer";

export const Header = () => {
  const [openMenuDrawer, setOpenMenuDrawer] = useState(false);
  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        backgroundColor: blackTone900,
        display: "flex",
        width: "100%",
        zIndex: 1,
        justifyContent: "center",
        paddingX: {
          xs: "24px",
          md: "40px",
        },
      }}
    >
      <Stack
        sx={{
          display: "flex",
          height: "74px",
          justifyContent: "space-between",
          flexDirection: "row",
          maxWidth: "1520px",
          alignItems: "center",
          width: "100%",
          paddingY: "10px",
        }}
      >
        <Link href="/" display={"flex"}>
          <LogoSVG />
        </Link>
        <IconButton
          sx={{ display: { md: "none" } }}
          color="primary"
          onClick={() => setOpenMenuDrawer(true)}
        >
          <MenuIcon />
        </IconButton>

        <Stack
          sx={{ flexDirection: "row", display: { xs: "none", md: "flex" } }}
          gap={1}
        >
          <Link
            underline="none"
            href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xc01b733b2ef479086b80949676a673346b531aa2&chain=mainnet"
            target="_blank"
            rel="noopener"
          >
            <Button variant="outlined" color="secondary">
              Buy $League
            </Button>
          </Link>
          <Link
            underline="none"
            href="https://t.me/leaguebot_official"
            target="_blank"
            rel="noopener"
          >
            <Button variant="outlined">Telegram</Button>
          </Link>
        </Stack>
      </Stack>
      <MenuDrawer
        open={openMenuDrawer}
        onClose={() => setOpenMenuDrawer(false)}
      />
    </Box>
  );
};

export default Header;
