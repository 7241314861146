import { Dialog, DialogContent } from '@mui/material'

type VideoPlayerProps = {
  open: boolean
  onClose: () => void
}

export const VideoPlayer = ({ open, onClose }: VideoPlayerProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='lg'
      PaperProps={{
        sx: {
          backgroundColor: '#000',
          borderRadius: { xs: '12px', md: '40px' },
        },
      }}
    >
      <DialogContent sx={{ padding: { xs: '2px', md: '20px 24px' } }}>
        <video width='100%' autoPlay height='100%' controls>
          <source
            src='https://static.leaguebot.io/presentation.mp4'
            type='video/mp4'
          />
        </video>
      </DialogContent>
    </Dialog>
  )
}

export default VideoPlayer
