import { Box, Grid, Stack, Typography, Button, Link } from "@mui/material";
import RevenueGraphics from "../assets/new-design/revenueGraphics.png";
import { blackTone100 } from "../styles/constants";

export const RevenueShareSection = () => {
  return (
    <Grid
      id="revenue-share"
      container
      justifyContent={"space-between"}
      alignItems={"center"}
      maxWidth={"1520px"}
      my={{ xs: 4, md: 8 }}
    >
      <Grid item xs={12} md={6}>
        <Box sx={{ height: "100%" }}>
          <img src={RevenueGraphics} alt="Revenue-Graphics" width={"100%"} />
        </Box>
      </Grid>
      <Grid item xs={12} md={5}>
        <Stack gap={3} maxWidth={"642px"}>
          <Typography variant="h1">Revenue Share</Typography>
          <Typography variant="body1" color={blackTone100}>
            Rewards are proportional to the amount of tokens held. Holders
            receive 40% of transaction fees. Must hold 2500 $LEAGUE tokens to be
            eligible.
          </Typography>
          <Stack flexDirection={"row"} gap={"20px"} paddingTop={"42px"}>
            <Link
              underline="none"
              href="https://t.me/leaguebot_official"
              target="_blank"
              rel="noopener"
            >
              <Button variant="contained" color="primary">
                Join Telegram
              </Button>
            </Link>
            <Link
              underline="none"
              href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xc01b733b2ef479086b80949676a673346b531aa2&chain=mainnet"
              target="_blank"
              rel="noopener"
            >
              <Button variant="outlined" color="secondary" size="large">
                Buy $League
              </Button>
            </Link>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};
export default RevenueShareSection;
