import { Box } from '@mui/material'
import LeftPhone from '../assets/new-design/phones/leftPhone.png'
import RightPhone from '../assets/new-design/phones/rightPhone.png'
import Background from '../assets/new-design/phones/Background.png'

export const PhonesAnimations = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        backgroundImage: `url(${Background})`,
        backgroundPosition: 'center',
        width: '100%',
        minHeight: { xs: '375px', sm: '400px', md: '500px' },

        backgroundSize: 'cover',
        maxWidth: { xs: '460px', md: 'unset' },
      }}
    >
      <Box
        sx={{
          width: { xs: '59%', sm: '60%', md: '61%', lg: '65%', xl: '75%' },
          height: '100%',
          position: 'absolute',
          top: { xs: '8%', md: '12%', lg: '10%', xl: '20%' },
          left: { xs: '13%', sm: '18%', md: '18%', lg: '14%', xl: '-2%' },

          animationName: 'up',
          animationDuration: '5s',
          animationIterationCount: 'infinite',
        }}
      >
        <img src={LeftPhone} alt='Phones-Graphics' width={'100%'} />
      </Box>
      <Box
        sx={{
          width: { xs: '50%', sm: '50%', md: '50%', lg: '55%', xl: '65%' },
          height: '100%',
          position: 'absolute',
          top: { xs: '12%', md: '20%', lg: '15%', xl: '25%' },
          right: { xs: '8%', sm: '5%', md: '5%', lg: '1%', xl: '-2%' },

          animationName: 'down',
          animationDuration: '5s',
          animationIterationCount: 'infinite',
        }}
      >
        <img src={RightPhone} alt='Phones-Graphics-right' width={'100%'} />
      </Box>
    </Box>
  )
}

export default PhonesAnimations
